.page__header {
	background: $color-white;
	padding: rem(12) 0 rem(8);
	@include mq($bp-tablet) {
		padding: rem(22) 0 rem(18);
	}
}
.page__brand {
	text-decoration: none;
}
.page__logo {
	height: rem(35);
	@include mq($bp-tablet) {
		height: rem(45);
	}
}
