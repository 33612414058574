.page__hero {
	flex-grow: 1;
	height: 100%;
	@include mq(480) {
		overflow: hidden;
	}
	.wrapper {
		height: 100%;
		position: relative;
		@include mq(480) {
			align-items: center;
			display: flex;
			justify-content: flex-start;
			&::after {
				height: rem(450);
				left: 100%;
				margin-top: 0;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				width: rem(280);
			}
		}
		@include mq($bp-tablet) {
			&::after {
				height: rem(750);
				left: 50%;
				transform: translate(rem(100), -50%);
				width: rem(480);
			}
		}
		&::after {
			background: img('apps-2x.png') center center no-repeat;
			background-size: auto 100%;
			content: '';
			display: block;
			height: 230px;
			margin-top: 45px;
		}
	}
}
.page__content {
	padding-top: rem(45);
	text-align: center;

	@include mq(480) {
		margin: rem(-45) rem(150) 0 0;
		padding-top: 0;
		text-align: left;
	}
	@include mq($bp-tablet) {
		max-width: rem(460);
	}
}
.page__title {
	color: $color-white;
	font-family: $font-title;
	font-size: rem(28);
	font-weight: bold;
	line-height: 1.33;
	margin: 0 0 rem(15);

	@include mq($bp-tablet) {
		font-size: rem(45);
	}
}
.page__subtitle {
	font-family: $font-title;
	font-size: rem(20);
	font-weight: bold;
	line-height: 1.5;
	margin: 0 0 rem(15);

	@include mq($bp-tablet) {
		font-size: rem(30);
	}
}
.page__description {
	font-family: $font-text;
	font-size: rem(15);
	line-height: 1.5;
	margin: 0;

	@include mq($bp-tablet) {
		font-size: rem(20);
	}
}
.page__contact-link {
	color: $color-white;
}
