// Colors
$color-reddish: #db3528;
$color-yellow-orange: #f6ae0b;
$color-brown-grey: #a8a8a8;
$color-brownish-grey: #676565;
$color-black: #000000;
$color-white: #ffffff;

$color_title: $color-black;
$color_text: $color-black;
$color_link: $color-white;



// dimensions
$dim-sitewidth: 990;
$dim-radius: 0;





// Fonts
$font-fallback: arial, sans-serif;
$font-text: 'Open Sans', $font-fallback;
$font-title: 'Miriam Libre', $font-fallback;
$font-size: 16;
$font-line-height: 130%;
$font-regular: 400;
$font-bold: 700;





// Effects
$time-transition: .5s;





// Paths
$theme: '../img/layout/';





// Levels
$levels: background, content, header, menu, cookies;





// BreakPoints
$bp-mobile: 320;
$bp-tablet: 768;
$bp-desktop: 1024;
$bp-desktop-hd: $dim-sitewidth + ( 75 * 2 );
