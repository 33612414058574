@import url("https://fonts.googleapis.com/css?family=Miriam+Libre:400,700|Open+Sans:400,700&display=swap");

body {
  font-family: "Open Sans", arial, sans-serif;
}

.snitch {
  background: #000000;
  border-radius: 0 0 3px 3px;
  color: #ffffff;
  font-family: arial, sans-serif;
  left: 50%;
  opacity: .3;
  padding: .5em 1em;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  z-index: 666;
}

html,body {
  height: 100%;
}

body {
  background: #db3528;
  margin: 0;
}

.wrapper {
  margin: 0 0.9375rem;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 37.5rem;
  background-image: linear-gradient(to bottom, #f6ae0b -70%, #db3528 100%);
}

.page__main {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page__header {
  background: #ffffff;
  padding: 0.75rem 0 0.5rem;
}

.page__brand {
  text-decoration: none;
}

.page__logo {
  height: 2.1875rem;
}

.page__footer {
  padding: 0.9375rem 0;
}

.page__footer-content {
  color: #ffffff;
  font-family: "Miriam Libre", arial, sans-serif;
  margin: 0;
  text-align: center;
}

.page__hero {
  flex-grow: 1;
  height: 100%;
}

.page__hero .wrapper {
  height: 100%;
  position: relative;
}

.page__hero .wrapper::after {
  background: url("../img/layout/apps-2x.png") center center no-repeat;
  background-size: auto 100%;
  content: '';
  display: block;
  height: 230px;
  margin-top: 45px;
}

.page__content {
  padding-top: 2.8125rem;
  text-align: center;
}

.page__title {
  color: #ffffff;
  font-family: "Miriam Libre", arial, sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.33;
  margin: 0 0 0.9375rem;
}

.page__subtitle {
  font-family: "Miriam Libre", arial, sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 0.9375rem;
}

.page__description {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 0;
}

.page__contact-link {
  color: #ffffff;
}

@media screen and (min-width: 23.4375em) {
  .wrapper {
    margin: 0 1.5625rem;
  }
}

@media screen and (min-width: 30em) {
  .page__hero {
    overflow: hidden;
  }

  .page__hero .wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  .page__hero .wrapper::after {
    height: 28.125rem;
    left: 100%;
    margin-top: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 17.5rem;
  }

  .page__content {
    margin: -2.8125rem 9.375rem 0 0;
    padding-top: 0;
    text-align: left;
  }
}

@media screen and (min-width: 48em) {
  .wrapper {
    margin: 0 3.125rem;
  }

  .page {
    min-height: 60.625rem;
  }

  .page__header {
    padding: 1.375rem 0 1.125rem;
  }

  .page__logo {
    height: 2.8125rem;
  }

  .page__hero .wrapper::after {
    height: 46.875rem;
    left: 50%;
    transform: translate(6.25rem, -50%);
    width: 30rem;
  }

  .page__content {
    max-width: 28.75rem;
  }

  .page__title {
    font-size: 2.8125rem;
  }

  .page__subtitle {
    font-size: 1.875rem;
  }

  .page__description {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 64em) {
  .wrapper {
    margin: 0 4.6875rem;
  }
}

@media screen and (min-width: 71.25em) {
  .wrapper {
    margin: 0 auto;
    max-width: 61.875rem;
  }
}
