// COMMON
@include snitch();
html,
body {
	height: 100%;
}
body {
	background: $color-reddish;
	margin: 0;
}
.wrapper {
	margin: 0 rem(15);

	@include mq(375) {
		margin: 0 rem(25);
	}

	@include mq($bp-tablet) {
		margin: 0 rem(50);
	}
	@include mq($bp-desktop) {
		margin: 0 rem(75);
	}
	@include mq($bp-desktop-hd) {
		margin: 0 auto;
		max-width: rem($dim-sitewidth);
	}
}





// PAGE
.page {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: rem(600);
	background-image: linear-gradient(to bottom, $color-yellow-orange -70%, $color-reddish 100%);
	@include mq($bp-tablet) {
		min-height: rem(970);
	}
}
.page__main {
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
}
